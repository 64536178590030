import Logo from "lib/components/Logo";
import { NextPage } from "next";
import LandingPage from "lib/components/LandingPage";

const Page: NextPage = () => {
  return (
    <LandingPage
      title="404 - bpfdeploy.io"
      description="HTTP Status Error - 404"
    >
      <div className="max-w-7xl mx-auto px-4 py-16 pb-16 sm:px-6 sm:pb-24 lg:pb-32">
        <Logo small white />
        <h1>
          <span className="mt-1 block tracking-tight font-medium text-5xl sm:text-6xl md:text-8xl">
            <span className="block text-gray-900">404</span>
          </span>
        </h1>
        <p className="mt-3 sm:mt-5 text-gray-600 text-xl sm:text-2xl lg:text-3xl">
          Page not found
        </p>
      </div>
    </LandingPage>
  );
};

export default Page;
